//index-about slide & btn position:top
let slidePreBtn = $('.index-content .index-about .index-about-content .pre-btn')
let slideNextBtn = $('.index-content .index-about .index-about-content .next-btn')
let aboutFigure = $('.index-content .index-about .index-about-content figure')
let slideIndex = 0

slidePreBtn.on('click', function(){
    slideIndex == 0 ? slideIndex = 0 : slideIndex-=1
    aboutFigure.css('transform', `translateX(-${slideIndex}00%)`)
})

slideNextBtn.on('click', function(){
    if(slideIndex < aboutFigure.length-1){
        slideIndex+=1
    }

    aboutFigure.css('transform', `translateX(-${slideIndex}00%)`)
})

//index-area change img
let areaImg = $('.index-area img')
let areaLi = $('.index-content .index-area > ul li')

areaLi.on('mousemove', function(){
    areaImg.attr('src', `storage/images/resources/${$(this).attr('data-img')}`)
})